/* eslint-disable @typescript-eslint/camelcase */
import {
  AFFILIATION_TYPES,
  AFFILIATION_REQUEST_STATUS,
  DOCUMENT_TYPES,
  APPLICATION_TYPES,
  HIRING_MODE_TYPES,
  PLAN_TYPES,
  COMPANY_PLAN_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  INCREASE_PLAN_ALLOWED_TYPES,
  APPLICATION_SUBTYPES,
  CORPORATIVE_PLAN_TYPES
} from '~constants/affiliations';

const affiliationRequestSpanish = {
  name: 'Solicitudes OSDE',
  history: 'Historial',
  listName: 'Listado de solicitudes',
  detailTitle: 'Detalle de solicitud',
  initialEmail: {
    send: {
      complete: 'Enviar e-mail de inicio',
      short: 'Enviar',
      title: 'Click para enviar e-mail de inicio'
    },
    resend: {
      complete: 'Reenviar e-mail de inicio',
      short: 'Reenviar',
      title: 'Click para reenviar e-mail de inicio'
    }
  },
  resendUserValidationEmail: {
    complete: 'Reenviar e-mail de validación de datos',
    short: 'Reenviar',
    title: 'Click para reenviar e-mail de validación de datos'
  },
  validateAffiliation: 'Evaluar',
  validateAffiliationTitle: 'Click para ir al detalle y evaluar la solicitud de afiliación',
  createTitle: 'Nueva solicitud',
  modals: {
    title: '¿Estás seguro?',
    initialEmail: {
      send: 'Recordá que debés enviar el trámite completo y evitar enviar documentación por correo. Todavía estás a tiempo de subir más archivos. ¡El analista de afiliaciones te agradece tu dedicación!',
      resend: 'Estás por reenviar el e-mail para iniciar el proceso de afiliación.',
      success: 'Se ha enviado el e-mail correctamente'
    },
    userValidationEmail: {
      resend: 'Estás por reenviar el e-mail para que el afiliado valide sus datos.',
      success: 'Se ha enviado el e-mail correctamente'
    },
    form: {
      createSuccess: 'Se creó con éxito la nueva solicitud de afiliación.',
      editSuccess: 'Se editó con éxito los datos de la afiliación.'
    },
    titleHistorial: 'Histórico de pasos',
    titlePotentialRisk: 'Potencial de riesgo'
  },
  fields: {
    action: 'Acción',
    id: 'ID',
    origin: 'Origen',
    promoterId: 'Promotor',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'E-mail',
    emailConfirmation: 'Confirmación de e-mail',
    phoneNumber: 'Teléfono',
    document: 'Documento',
    cuit: 'CUIT de la empresa',
    type: 'Tipo de socio',
    planType: 'Tipo de plan',
    filial: 'Filial',
    cap: 'Cap',

    affiliationTypes: {
      [AFFILIATION_TYPES.DIRECT]: 'Directo',
      [AFFILIATION_TYPES.DIRECT_GROUP]: 'Directo Por Empresa',
      [AFFILIATION_TYPES.MANDATORY]: 'Obligatorio',
      [AFFILIATION_TYPES.MONOTAX]: 'Monotributo',
      [AFFILIATION_TYPES.AGREEMENT]: 'Convenio',
      [AFFILIATION_TYPES.COMPLEMENT]: 'Complementación'
    },
    hiringMode: 'Modalidad de contratación',
    hiringModeTypes: {
      [HIRING_MODE_TYPES.APO]: 'APO',
      [HIRING_MODE_TYPES.PLA]: 'PLA'
    },
    planTypes: {
      [PLAN_TYPES.BINARIO]: 'Binario',
      [PLAN_TYPES.CORPORATIVO]: 'Corporativo'
    },
    companyPlan: 'Seleccione el plan',
    companyPlanTypes: {
      [COMPANY_PLAN_TYPES.P210]: '210',
      [COMPANY_PLAN_TYPES.P310]: '310',
      [COMPANY_PLAN_TYPES.P410]: '410',
      [COMPANY_PLAN_TYPES.P450]: '450',
      [COMPANY_PLAN_TYPES.P510]: '510',
      [COMPANY_PLAN_TYPES.P6030]: '6030'
      // [COMPANY_PLAN_TYPES.P6031]: '6031'
    },
    corporativePlantypes: {
      [CORPORATIVE_PLAN_TYPES.P8360]: '8-360',
      [CORPORATIVE_PLAN_TYPES.P8430]: '8-430',
      [CORPORATIVE_PLAN_TYPES.P8260]: '8-260'
    },
    increasePlanAllowed: 'Permite mejorar plan',
    increasePlanAllowedTypes: {
      [INCREASE_PLAN_ALLOWED_TYPES.true]: 'Si',
      [INCREASE_PLAN_ALLOWED_TYPES.false]: 'No'
    },
    isPaymentResponsible: 'Socio abona diferencia',
    isPaymentResponsibleTypes: {
      [IS_PAYMENT_RESPONSIBLE_TYPES.true]: 'Si',
      [IS_PAYMENT_RESPONSIBLE_TYPES.false]: 'No'
    },
    applicationType: 'Tipo de formulario',
    applicationTypes: {
      [APPLICATION_TYPES.FULL]: 'F1 + DDJJ',
      [APPLICATION_TYPES.F1]: 'F1',
      [APPLICATION_TYPES.BENEFICIARY]: 'Beneficiario',
      [APPLICATION_TYPES.DDJJ]: 'Pre-DDJJ',
      [APPLICATION_TYPES.OTHERS]: 'Otros'
    },
    applicationSubtype: 'Subtipo de formulario',
    applicationSubtypes: {
      [APPLICATION_SUBTYPES.CAMBIO_DE_CONDICION_IVA]: 'Cambio de condición de IVA',
      [APPLICATION_SUBTYPES.CAMBIO_DE_PLAN]: 'Cambio de plan',
      [APPLICATION_SUBTYPES.BAJA]: 'Baja',
      [APPLICATION_SUBTYPES.PLURIEMPLEO]: 'Pluriempleo',
      [APPLICATION_SUBTYPES.SUMATORIO_DE_APORTES]: 'Sumatoria de aportes',
      [APPLICATION_SUBTYPES.TRASPASOS_JERARQUIZACION]: 'Traspasos / Jerarquización',
      [APPLICATION_SUBTYPES.CAMBIO_GRUPO_DIRECTO_EMPRESA]: 'Cambio a grupo directo por empresa',
      [APPLICATION_SUBTYPES.LICENCIAS_SUBSIDIOS]: 'Licencias / Subsidios',
      [APPLICATION_SUBTYPES.SUBCIDIO_POR_FALLECIMIENTO]: 'Subsidio por fallecimiento',
      [APPLICATION_SUBTYPES.PLAN_ESPECIAL]: 'Plan especial / Temporal',
      [APPLICATION_SUBTYPES.CAMBIO_EMPLEADOR_APORTE_LEY]: 'Cambio empleador solo aporte de ley',
      [APPLICATION_SUBTYPES.PLAN_6030]: 'Plan 6-030'
    },
    planValidityDate: 'Vigencia (fecha de inicio del plan)',
    documentType: 'Tipo de documento',
    documentNumber: 'Número de documento',
    status: 'Estado',
    statusTypes: {
      [AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING]: 'Inicio pendiente',
      [AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT]: 'E-mail enviado',
      [AFFILIATION_REQUEST_STATUS.FORM_LINK_OPENED]: 'Link abierto',
      [AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION]: 'Confirmación PDF pendiente',
      [AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION]: 'Revisar PDF',
      [AFFILIATION_REQUEST_STATUS.FINISHED]: 'En proceso alta',
      [AFFILIATION_REQUEST_STATUS.REVISION]: 'En revisión',
      [AFFILIATION_REQUEST_STATUS.USER_REJECTED]: 'Datos incorrectos',
      [AFFILIATION_REQUEST_STATUS.EXPIRED]: 'Vencido',
      [AFFILIATION_REQUEST_STATUS.CANCELED]: 'Cancelado',
      [AFFILIATION_REQUEST_STATUS.SAP]: 'Sap',
      [AFFILIATION_REQUEST_STATUS.ZCAR]: 'ZCAR',
      [AFFILIATION_REQUEST_STATUS.ENABLED]: 'Habilitado'
    },
    documentTypes: {
      [DOCUMENT_TYPES.DNI]: 'DNI',
      [DOCUMENT_TYPES.PASSPORT]: 'Pasaporte',
      [DOCUMENT_TYPES.PASSPORT_SAP]: 'Pasaporte',
      [DOCUMENT_TYPES.CUIL]: 'CUIL'
    },
    potentialRisk: 'Potencial de riesgo',
    titleInsurance: 'Tiene seguro de vida'
  },
  documents: {
    f1: { title: 'Formulario F1' },
    sworn: { title: 'Declaración jurada' },
    attachments: { title: 'Documentación' },
    promoterAttachments: { title: 'Adjuntos' },
    othersAttachments: { title: 'Otros' },
    termAndConditions: { title: 'Términos y condiciones' }
  },
  evaluate: {
    [AFFILIATION_REQUEST_STATUS.FINISHED]: 'Dar alta proceso',
    [AFFILIATION_REQUEST_STATUS.REVISION]: 'Llevar a revisión',
    [AFFILIATION_REQUEST_STATUS.CANCELED]: 'Cancelar solicitud',
    [AFFILIATION_REQUEST_STATUS.ZCAR]: 'ZCAR',
    [AFFILIATION_REQUEST_STATUS.ENABLED]: 'Habilitado',
    modal: {
      [AFFILIATION_REQUEST_STATUS.FINISHED]: {
        message: 'Estás por dar de alta el proceso de afiliación.',
        success: 'Se ha dado de alta el proceso de afiliación correctamente'
      },
      [AFFILIATION_REQUEST_STATUS.REVISION]: {
        message: 'Estás por llevar a revisión el proceso de afiliación.',
        success: 'Se ha llevado a revisión el proceso de afiliación correctamente'
      },
      [AFFILIATION_REQUEST_STATUS.CANCELED]: {
        message: 'Estás por llevar a cancelado el proceso de afiliación.',
        success: 'Se ha llevado a cancelado el proceso de afiliación correctamente'
      },
      [AFFILIATION_REQUEST_STATUS.PENDING_PROSPECT_VALIDATION]: {
        message: 'Estás por reenviar el e-mail para iniciar el proceso de afiliación.',
        success: 'El e-mail se ha enviado con exito.'
      },
      [AFFILIATION_REQUEST_STATUS.ENABLED]: {
        success: 'Solicitud actualizada a estado Habilitado correctamente.'
      },
      resend_documentation: {
        message: 'Estás por enviar  el e-mail.',
        success: 'El e-mail se ha enviado con exito.'
      }
    }
  },
  tooltip: 'Modificado el ',
  familyGroup: {
    partner: 'Cónyuge',
    child: 'Hijo',
    stepson: 'Hijo',
    son_under_guard: 'Hijo',
    daughter: 'Hija',
    prospect: 'Titular',
    familyGroup: 'Grupo familiar',
    age: '%{age} años',
    exceededAge: 'La edad excede la máxima permitida.'
  },
  potentialRisk: {
    detail: 'Ver detalle',
    riskText: 'Presenta riesgos en %{reasons}'
  }
};

export default affiliationRequestSpanish;
