/* eslint-disable complexity */
import React, { useState, useMemo, useCallback } from 'react';
import { SimpleShowLayout } from 'react-admin';
import { SolicitudBinariaRequest, Promoter } from '~utils/types';
import { useCommonStyles } from '~app/styles';
import useModal from '~app/hooks/useModal';
import DetailActionButtons from '~components/DetailActionButtons';
import ViewInput from '~components/ViewInput';
import { RESOURCES_PATH } from '~config/providers/constants';
import CustomButton from '~components/CustomButton';
import CustomModal from '~components/CustomModal';
import FileValue from '~components/FileValue';

// TODO: Volver a como estaba antes después de hacer las pruebas
const env = process.env.ENVIRONMENT;
const loggingFlag = env !== 'prod';

import {
  isCompleta,
  // isPendienteUser,
  canSendInitialEmail,
  getEtiquetas,
  evaluatePayload,
  isPendienteProspect
} from '../helperFunctions';
import StatusField from '../SolicitudBinariaGrid/StatusField';
import SolicitudBinariaForm from '../SolicitudBinariaCreate/SolicitudBinariaForm';
import SolicitudBinariaFilled from '../SolicitudBinariaCreate/SolicitudBinariaFilled';
import { useStyles } from '../binaria-styles';

interface Props {
  record: SolicitudBinariaRequest;
  resource: string;
  permissions: Promoter;
}

interface RequestPayload {
  id?: number;
}

export default function SolicitudBinariaDetail(props: Props) {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const { record } = props;
  const estado = useMemo(() => props.record.state, [props.record.state]);
  const handleSetIsEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const completed = isCompleta(estado);
  const pending = isPendienteProspect(estado);
  const showInitialEmailBtn = canSendInitialEmail(estado);
  // const userValidationPending = isPendienteUser(estado);
  const { label, title, modalMsg } = useMemo(() => getEtiquetas(estado), [estado]);
  const { id, actions, forms } = record;
  const documents = record.documents?.length === 0 || !record.documents ? [] : record.documents;

  const [modalData, dispatchRequest, loading, onOpenModal] = useModal<RequestPayload, RequestPayload>({
    resource: props.resource,
    requestType: 'UPDATE_ESTADO_SOLICITUD_BINARIA',
    requestPayload: evaluatePayload,
    modalName: 'evaluate',
    successMessageNotify: 'Se ha enviado el email correctamente'
  });

  const handleOpenModal = useCallback(() => {
    onOpenModal({ id: props.record.id, newState: props.record.actions[0].state });
  }, [props.record.id, props.record.actions, onOpenModal]);

  if (loggingFlag) {
    console.log('forms: ', forms);
    console.log('documents: ', documents);
    console.log('package.json: ', '9');
  }

  return (
    <>
      <DetailActionButtons />
      <SimpleShowLayout {...props}>
        <>
          <h1 className={commonStyles.title}>Detalles de solicitud</h1>
        </>
        <SolicitudBinariaForm {...props} esEdicion enEdicion={isEditing} onSetEditing={handleSetIsEditing} />
        <ViewInput
          label="Estado"
          content={<StatusField state={estado} rounded={false} />}
        // innerContainerClassName={styles.spacing}
        />
        {props && props.record && props.record.links && props.record.links.length > 0 && (
          <ViewInput
            label="Links"
            content={props.record.links.map(link => (
              <FileValue key={link.url} url={link.url} name={link.name} icon={false} />
            ))}
          />
        )}
        <>
          {showInitialEmailBtn && (
            <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
              <CustomButton label={label} title={title} onClick={() => handleOpenModal()} />
            </div>
          )}
          {/* {userValidationPending && (
            <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
              <CustomButton label={label} title={title} onClick={() => handleOpenModal()} />
            </div>
          )} */}

          {(pending || completed) && (
            <SolicitudBinariaFilled
              record={record}
              id={id}
              forms={forms}
              documents={documents}
              actions={actions}
              resource={RESOURCES_PATH.binaria}
            />
          )}
        </>
      </SimpleShowLayout>
      {modalData?.id && (
        <CustomModal
          title="¿Estás seguro?"
          modalName="evaluate"
          onConfirm={dispatchRequest}
          loading={loading}
          showCloseIcon={false}
        >
          <p className={styles.modalContent}>{modalMsg}</p>
        </CustomModal>
      )}
    </>
  );
}
